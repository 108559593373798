import axios from "axios";
import Cookies from "js-cookie";
import { buffers, END, eventChannel } from "redux-saga";
import config from "../../../config.json";
export const PROD_BASE_URL = "https://amgenentrustpatient.ca";
export const STAGE_BASE_URL = "https://amgenpatientstaging.azurewebsites.net";
export const DEV_BASE_URL = "https://localhost:44345";
//export const STAGE_BASE_URL = "https://localhost:44345";
const apiPath = "/api";
const ENV = process.env.NODE_ENV;
const USE_PROD = process.env.NODE_ENV === 'production';
class ApiManager {
    static instance;
    axiosInstance;
    defaultHeaders = () => {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Accept-Language": ApiManager.getAcceptLanguage(localStorage.getItem("i18nextLng") || "en")
        };
    };
    static getAcceptLanguage(languageCode) {
        if (languageCode === "fr") {
            return "fr-CA";
        }
        return "en-CA";
    }
    authToken = Cookies.get("token");
    deviceToken = null;
    constructor() {
        console.log(ENV);
        this.axiosInstance = axios.create();
        if (ENV === "production")
            this.axiosInstance.defaults.baseURL = PROD_BASE_URL;
        else if (ENV === "staging")
            this.axiosInstance.defaults.baseURL = STAGE_BASE_URL;
        else
            this.axiosInstance.defaults.baseURL = DEV_BASE_URL;
        this.axiosInstance.defaults.baseURL += apiPath;
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new ApiManager();
        }
        return this.instance;
    }
    setToken(token) {
        this.authToken = token;
    }
    getToken() {
        return this.authToken;
    }
    setTokenFromCookie() {
        this.authToken = Cookies.get("token");
    }
    setDeviceToken(token) {
        this.deviceToken = token;
    }
    get = (endpoint, params, deviceToken, includeAppType = true) => {
        if (includeAppType)
            params = { ...params, applicationType: config.applicationType };
        return this.axiosInstance
            .get(endpoint, {
            headers: {
                ...this.defaultHeaders(),
                ...(deviceToken ? { "Device-Reg-Token": this.deviceToken } : {}),
                ...(this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}),
                ...{ ApplicationType: config.applicationType }
            },
            params
        })
            .catch((error) => {
            return Promise.reject({
                statusCode: error.response.status,
                data: error.response.data
            });
        });
    };
    post = (endpoint, data, deviceToken, includeAppType = true) => {
        if (includeAppType)
            data = { ...data, applicationType: config.applicationType };
        return this.axiosInstance
            .post(endpoint, data, {
            headers: {
                ...this.defaultHeaders(),
                ...(deviceToken ? { "Device-Reg-Token": this.deviceToken } : {}),
                ...(this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}),
                ...{ ApplicationType: config.applicationType }
            }
        })
            .catch((error) => {
            return Promise.reject({
                statusCode: error.response.status,
                data: error.response.data
            });
        });
    };
    put = (endpoint, data, headers, includeAppType = true) => {
        if (includeAppType)
            data = { ...data, applicationType: config.applicationType };
        return this.axiosInstance
            .put(endpoint, data, {
            headers: {
                ...this.defaultHeaders(),
                ...headers,
                ...(this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}),
                ...{ ApplicationType: config.applicationType }
            }
        })
            .catch((error) => {
            return Promise.reject({
                statusCode: error.response.status,
                data: error.response.data
            });
        });
    };
    delete = (endpoint, params, includeAppType = true) => {
        if (includeAppType)
            params = { ...params, applicationType: config.applicationType };
        return this.axiosInstance
            .delete(endpoint, {
            headers: {
                ...this.defaultHeaders(),
                ...(this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}),
                ...{ ApplicationType: config.applicationType }
            },
            params
        })
            .catch((error) => {
            return Promise.reject({
                statusCode: error.response.status,
                data: error.response.data
            });
        });
    };
    createUploadFileChannel = (endpoint, formData) => {
        return eventChannel((emitter) => {
            const xhr = new XMLHttpRequest();
            const onProgress = (e) => {
                if (e.lengthComputable) {
                    const progress = e.loaded / e.total;
                    emitter({ progress });
                }
            };
            const onFailure = () => {
                emitter({ err: new Error("Upload failed") });
                emitter(END);
            };
            xhr.upload.addEventListener("progress", onProgress);
            xhr.upload.addEventListener("error", onFailure);
            xhr.upload.addEventListener("abort", onFailure);
            xhr.onreadystatechange = () => {
                const { readyState, status, responseText } = xhr;
                if (readyState === 4) {
                    if (status >= 200 && status < 300) {
                        emitter({ success: true });
                        emitter(END);
                    }
                    else {
                        onFailure();
                    }
                }
            };
            let baseURL = DEV_BASE_URL;
            if (ENV === "production")
                baseURL = PROD_BASE_URL;
            else if (ENV === "staging")
                baseURL = STAGE_BASE_URL;
            xhr.open("POST", `${baseURL}${apiPath}${endpoint}`, true);
            xhr.setRequestHeader("Authorization", `Bearer ${this.authToken}`);
            xhr.setRequestHeader("Cache-Control", "no-cache");
            xhr.send(formData);
            return () => {
                xhr.upload.removeEventListener("progress", onProgress);
                xhr.upload.removeEventListener("error", onFailure);
                xhr.upload.removeEventListener("abort", onFailure);
                xhr.onreadystatechange = null;
                xhr.abort();
            };
        }, buffers.sliding(2));
    };
}
export default ApiManager;
